import React, { Component, PureComponent, useState, useRef, forwardRef, useEffect, Fragment } from 'react';
import PopupPage from "../../reactcomponents/PopupPage";
import { RewriteAnswerBoxContentCtnr } from '../../views/v5/errandCtnrs';
import { I } from '../../common/v5/config.js';
import { RightSidePanelHeader } from '../../reactcomponents/Sidepanel';
import { Spinner } from '../../reactcomponents/Spinner';
import { isElementAtBottom } from '../../common/v5/utils';
import { checkIfChatNotEmpty, isEmptyRichText } from '../../common/v5/helpers';
import { CHAT_CKEDITOR_ID } from '../../common/v5/constants';
import Helper from '../../reactcomponents/Helper'

export class RewriteAnswerPopup extends Component {
	constructor(props){
		super(props);
		this.closePopup = this.closePopup.bind(this);
	}
	closePopup() {
		this.props.handleToggle(false);
	}
	closeAIAnswer = () => {
		this.props.handleCloseAIAnswer(this.props.eid);
	}
	generateAnswer = ()=>{
		this.props.generateAnswer(this.props.currentQues);
	}
	regenerateAnswer = ()=>{
		this.props.generateAnswer(this.props.currentQues);
	}
	handleQuestionChange = (event)=>{
		const p = this.props;
		const target = event.target;
                p.onUpdateQuestion(target.value);
	}
	render() {
		const p = this.props;
		return (
			<PopupPage
				data-qa-id="rewrite-answer-backdrop-popup"
				extraClass="rewrite-answer-backdrop"
				innerClass="rewrite-answer"
				show={p.showPopup}
				onMinimize={this.closePopup}
				onClose={this.closeAIAnswer}
			>
				<RightSidePanelHeader
					headingTitle={I("Agent assist")}
					showMinimize={true}
					onClosePanel={this.closeAIAnswer}
					onMinimizePanel={this.closePopup}
					showActions={false}
				/>
				<RewriteAnswerBoxContentCtnr popup={true} isChat={p.isChat} />
			</PopupPage>
		);
	}
}

export class CtrlRewriteAnswerPopup extends PureComponent {
	constructor(props){
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if (this.props.onToggle) {
			this.props.onToggle(this.props.open);
		}
	}
	render() {
		let p=this.props;
		return(
			<div className="rewrite-answer-base-link">
				<a className="blue" onClick={this.toggle} data-qa-id={"QA_RewriteAnswerBasePopup"}>{p.title}</a>
			</div>
		);
	}
}

//List of AI answer context/helper to be used to help on answering the question
const REPHRASE = "rephrase";
const CHAT = "chat";
const REGENERATE = "re-generate";
const FRIENDLY_TONE = "friendly-tone";
const MAKE_LONGER = "make-longer";
const START_OVER = "start-over";
const REPHRASE_INIT = "rephrase-init";
const TRANSLATE = "translate";
const ADD_FAV_LANG = "add-fav-lang";

//Alphabetically ordered except english (on top)
//Using this one for now
const PRESET_AI_LANG_LIST = [
	{ title: I('English'), code: 'en' },
	{ title: I('Arabic'), code: 'ar' },
	{ title: I('Bahasa Malaysia'), code: 'ms' },
	{ title: I('Chinese (Simplified)'), code: 'zh-CN' },
	{ title: I('Danish'), code: 'da' },
	{ title: I('Dutch'), code: 'nl' },
	{ title: I('Finnish'), code: 'fi' },
	{ title: I('Filipino'), code: 'fil' },
	{ title: I('French'), code: 'fr' },
	{ title: I('German'), code: 'de' },
	{ title: I('Greek'), code: 'el' },
	{ title: I('Hindi'), code: 'hi' },
	{ title: I('Italian'), code: 'it' },
	{ title: I('Japanese'), code: 'ja' },
	{ title: I('Korean'), code: 'ko' },
	{ title: I('Norwegian'), code: 'no' },
	{ title: I('Polish'), code: 'pl' },
	{ title: I('Portuguese'), code: 'pt' },
	{ title: I('Russian'), code: 'ru' },
	{ title: I('Spanish'), code: 'es' },
	{ title: I('Swedish'), code: "sv" },
	{ title: I('Thai'), code: 'th' },
	{ title: I('Turkish'), code: 'tr' },
	// Add more languages as needed
]

const ListOfAIEnhanceAnswerOptions = {
	'1': {
		title: I("Rephrase"),
		action: REPHRASE,
		hide: false
	},
	'2': {
		title: I("Change to friendly tone"),
		action: FRIENDLY_TONE,
		hide: false
	},
	'3': {
		title: I("Make longer"),
		action: MAKE_LONGER,
		hide: false
	},
	'4': {
		title: I("Start over"), //this will fetch a new answer from agent's reply editor and rephrase it and clear all current history in the panel
		action: START_OVER,
		hide: false
	},
	'5': {
		title: I("Rephrase"),
		action: REPHRASE_INIT,
		hide: true
	},
	'6': {
		title: I("Translate to"),
		action: TRANSLATE,
		hide: false,
		type: "select",
		data: PRESET_AI_LANG_LIST
	},
	'7': {
		title: I("Add to favorite languages"),
		action: ADD_FAV_LANG,
		hide: true
	}
}

const TranslationOptStyle = {
	color: "#6D6D6D",
	fontSize: "12px",
	lineHeight: "16px",
	fontWeight: "400",
	padding: "8px 4px",
}

//todo. move outside as a standard component
const SimpleSelectBoxWithFavorite = ({ list, show, isAtBottom, onClickOption }) => {
	const boxRef = useRef(null);

	const handleClickOption = (e, value) => {
		e.stopPropagation();
		onClickOption(TRANSLATE, value);
	}
	const handleClickFav = (e, value) => {
		e.stopPropagation();
		onClickOption(ADD_FAV_LANG, value);
	}

	const SelectBoxStyle = {
		background: '#FFF',
		border: '1px solid #FACFC8',
		borderRadius: '4px',
		zIndex: '9999',
		padding: '8px',
		gap: '4px',
		overflow: 'auto',
		width: '162px',
		boxShadow: '0px 3px 6px 0px #00000026',
		border: '1px solid #EAEAEA',
		display: show ? 'block' : 'none',
		bottom: isAtBottom ? '0px' : 'auto',
		position: 'absolute',
		maxHeight: isAtBottom ? '400px' : '-webkit-fill-available',
		left: isAtBottom ? '120px' : 'auto',
	}

	return (
		<div id="boxWrapper" ref={boxRef} style={SelectBoxStyle}>
			{
				list && Array.isArray(list) && list.length > 0 &&
					list.map((d, index) => {
						return (
							<div id="boxSelector" key={index} style={TranslationOptStyle} onClick={(e) => handleClickOption(e, d.code) }>{d.title}
								<i className={d.favorite ? "icon-star" : "icon-star-outline"} style={{float: 'right', color: '#D5D5D5', marginLeft: '5px', fontSize: '16px'}} onClick={(e) => handleClickFav(e, d.code)} ></i>
							</div>
						)
					})
			}
		</div>
	)
}

const AIEnhanceAnswerOptions = forwardRef(function ({ title, active, action, disabled, type, showOptions, data, setShowOptions, favLangs, onClick, onUpdateLangFavourites, onSelectLanguage }, ref) {
	const buttonRef = useRef(null);
	const [langOptions, setLangOptions] = useState(data);
	const [isBottom, setIsBottom] = useState(false);
	const AIEnhanceAnswerOptionsStyle = {
		padding: '4px 8px 4px 8px',
		borderRadius: '4px',
		border: disabled ? '1px solid #F08875' : active ? '1px solid #dd7361' : '1px solid #FACFC8',
		gap: '4px',
		cursor: 'pointer',
		background: disabled ? '#F08875' : active ? '#fdf8f7' : '#f8f8f8',
		pointerEvents: disabled ? 'none' : 'auto',
		position: isBottom ? 'relative' : 'initial'
	}
	const AIEnhanceAnswerOptionsTxtStyle = {
		color: disabled ? '#FFF' : '#EC6A52',
		fontSize: '12px', lineHeight: '16px', fontWeight: '400', whiteSpace: 'nowrap'
	};
	const handleClick = () => {
		onClick(action);
	}

	useEffect(() => {
		if(data && Array.isArray(data) && data.length > 0) {
			const newData = data.map((d) => {
				if(favLangs && favLangs.length > 0) {
					if(favLangs.includes(d.code)) {
						d.favorite = true;
					} else {
						d.favorite = false;
					}
				} else {
					d.favorite = false;
				}
				return d;
			}
			);
			newData.sort((a, b) => {
				if(a.favorite) {
					return -1;
				} else {
					return 1;
				}
			});
			newData.sort((a, b) => {
				if(a.favorite && b.favorite) {
					if(a.title < b.title) { return -1; }
					if(a.title > b.title) { return 1; }
					return 0;
				}
				if (!a.favorite && !b.favorite) {
					// English always on top if not in fav
					if (a.code === "en" && b.code !== "en") { return -1; }
					if (a.code !== "en" && b.code === "en") { return 1; }

					if (a.title < b.title) { return -1; }
					if (a.title > b.title) { return 1; }
					return 0;
				}
			});
			setLangOptions(newData);
		}
	}, [favLangs]);

	const handleClickOpt = (action, value) => {
		if(action == TRANSLATE) {
			if(onSelectLanguage && typeof onSelectLanguage === "function") {
				onSelectLanguage(value);
			}
			setShowOptions(false);
		} else if (action == ADD_FAV_LANG) {
			if(onUpdateLangFavourites && typeof onUpdateLangFavourites === "function") {
				onUpdateLangFavourites(value);
			}
		}
	}

	useEffect(() => {
		const isAtBottom = isElementAtBottom(buttonRef, ref);
		setIsBottom(isAtBottom);
	}, [showOptions]);

	return (
		<div ref={buttonRef} style={AIEnhanceAnswerOptionsStyle} onClick={handleClick}>
			<span style={AIEnhanceAnswerOptionsTxtStyle}>{title}</span>
			{
				type === "select" &&
				<i className={showOptions ? "icon-chevron-up" : "icon-chevron-down"} style={{color: disabled ? '#FFF' : '#EC6A52', margin: '0px 10px', fontSize: '12px'}}></i>
			}
			{
				type === "select" &&
					<SimpleSelectBoxWithFavorite show={showOptions} isAtBottom={isBottom} list={langOptions} onClickOption={handleClickOpt}/>
			}
		</div>
	)
})

const agentPromptBoxStyle = {
	background: '#FBEAE7',
	color: '#6D6D6D',
	display: 'flex',
	gap: '8px',
	padding: '8px 16px',
	margin: '10px 0px',
	borderRadius: '4px',
	width: '100%'
};

const GreetingPromptBoxStyle = {
	background: '#FDF8F7',
	color: '#6D6D6D',
	display: 'flex',
	gap: '8px',
	padding: '8px 16px',
	border: '1px solid #FACFC8',
	width: '100%',
	borderRadius: '4px',
	transition: 'max-height 0.5s ease-out',
	overflow: 'hidden',
}

const AIPromptBoxStyle = {
	background: '#FDF8F7',
	color: '#6D6D6D',
	display: 'flex',
	gap: '8px',
	padding: '8px 16px',
	border: '1px solid #FACFC8',
	width: '100%',
	borderRadius: '4px',
}

const innerTxtStyle = (reformat) => {
	return {
		width: '100%',
		wordBreak: 'break-word',
		overflowWrap: 'break-word',
		lineHeight: '16px',
		overflow: 'hidden',
		whiteSpace: reformat ? 'unset' : 'pre-line'
	}
}

const innertxtPromptStyle = {
	width: '100%',
	wordBreak: 'break-word',
	overflowWrap: 'break-word',
	lineHeight: '16px',
	overflow: 'hidden',
	whiteSpace: 'pre-line'
}

const CustomizeInstructionInfo = ({customAIInst, customAIResp}) => {
	const newCustomAIInst = customAIInst.split('\n').map((item, key) => {
		return <span key={key}>{item}<br /></span>
	});
	const newCustomAIResp = customAIResp.split('\n').map((item, key) => {
		return <span key={key}>{item}<br /></span>
	});

	return (
		<div>
			{
				customAIInst &&
				<div><span>{I("Custom Instruction")} :</span><br />
					{newCustomAIInst}
					<br />
					<br />
				</div>
			}
			{
				customAIResp &&
				<div>
					<span>{I('Custom Response')}:</span><br />
					{newCustomAIResp}
				</div>
			}
		</div>
	)
}

export const RewriteAnswerBoxContent = ({
	areaCustomAIInstructions
	, src = "ERRAND"
	, currentQues //this is actually current answer that agent has typed in the answer editor, gotta rename this
	, newAIContext
	, AISession
	, verticalView = false
	, isChat
	, area
	, dataSrc
	, popup
	, currentAnswer
	, onLoad
	, onSend
	, onCopy
	, onInsertAIAnswer
	, generatingAns
	, generateAnswer
	, agentFavouriteAITranslateLangs
	, onUpdateLangFavourites
	, eid
	, selectedLibraryQuestion
}) => {
	const messageComponentRef = useRef(null);
	const [liveChatTxt, setLiveChatTxt ] = useState('');
	const [activeEnhance, setActiveEnhance] = useState('');
	const [disableRephraseInit, setDisableRephraseInit] = useState(true);
	const [hasPrevAnswer, setHasPrevAnswer] = useState(false);
	const [showOptions , setShowOptions] = useState(false);
	const [hasAnswer, setHasAnswer] = useState(false);
	const [currentAction, setCurrentAction] = useState("");

	const chatEditor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
	const commonAIPrompt = I("Hello! How can I help you today?");

	let customAIInst = "";
	let customAIResp = "";

	if (areaCustomAIInstructions && areaCustomAIInstructions.customAIInstructions) {
		customAIInst = areaCustomAIInstructions.customAIInstructions.replace('[ANSWER]', currentQues);
	}

	if (areaCustomAIInstructions && areaCustomAIInstructions.customAIResponse) {
		customAIResp = areaCustomAIInstructions.customAIResponse.replace('[ANSWER]', currentQues);
	}

	useEffect(() => {
		onLoad(currentQues);
		if(isChat) {
			let chatCurrMsg = "";
			if (chatEditor && chatEditor.document && chatEditor.document.getBody()) {
				chatCurrMsg = chatEditor.document.getBody().getText();
			}
		}
	}, []);

	const enhanceAnswer = (action) => {
		let currAnswer = currentAnswer;
		let errandId = eid ? eid.toString() : selectedLibraryQuestion ? "qLibrary-"+selectedLibraryQuestion : "";
		let newHistory = newAIContext;
		setCurrentAction(action);
		if(src !== "ERRAND") {
			const ansEditor = CKEDITOR.instances["Library_answer"];
			if(ansEditor && ansEditor.document && ansEditor.document.getBody()) {
				currAnswer = ansEditor.document.getBody().getText();
			}
			newHistory = false;
		}
		if(isChat) {
			//rechecking value in editor for latest input
			if(chatEditor && chatEditor.document && chatEditor.document.getBody()) {
				currAnswer = chatEditor.document.getBody().getText();
				const chatIsNotEmpty = checkIfChatNotEmpty();
				if (!chatIsNotEmpty || currAnswer == "\n") {
					currAnswer = "";
				}
			}
		}
		let prompt = "";
		if(action !== TRANSLATE) {
			if(action === REPHRASE_INIT || action === START_OVER) {
				if (currAnswer) {
					prompt = currAnswer;
				} else {
					prompt = currentQues;
				}
				if(action === START_OVER && isChat) {
					prompt = currAnswer;
				}
				const isEmptyTxt = isEmptyRichText(prompt);
				if(isEmptyTxt) {
					alert(I("Please type something in the answer editor"));
					return;
				}
			} else {
				if(hasAnswer) {
					if(dataSrc.answers || dataSrc.answers.length > 0){
						const prevAnswer = dataSrc.answers.sort((a, b) => b.received - a.received)[0];
						prompt = prevAnswer.content;
					}
				} else {
					//if no previous answer to enhance, use the current one in the editor
					const isEmptyTxt = isEmptyRichText(currAnswer);
					if(isEmptyTxt) {
						alert(I("Please type something in the answer editor"));
						return;
					} else {
						prompt = currAnswer;
					}
				}
			}
			const payload = {
				area,
				content: prompt,
				context: action,
				newHistory,
				errandId,
				sessionId: action === START_OVER ? "" : AISession
			}
			setActiveEnhance(action);
			if(payload.content !== "") {
				onSend(action, payload);
			} else {
				console.log("Error: No answer to rephrase");
			}
			setShowOptions(false);
		} else {
			setShowOptions(!showOptions);
		}
	}

	const translateAnswer = (lang) => {
		let langTitle = "";
		PRESET_AI_LANG_LIST.forEach((d) => {
			if(d.code == lang) {
				langTitle = d.title;
			}
		});
		let prompt = currentQues;
		if (hasPrevAnswer) {
			const prevAnswer = dataSrc.answers.sort((a, b) => b.received - a.received)[0];
			prompt = prevAnswer ? prevAnswer.content ? prevAnswer.content : currentQues : currentQues;
		}
		prompt = '"' + prompt + '"';
		setActiveEnhance(TRANSLATE);
		const instructorStr = I("Translate the following text into") + " " + langTitle + " : \n";
		const errandId = eid ? eid.toString() : selectedLibraryQuestion ? "qLibrary-"+selectedLibraryQuestion : "";
		const payload = {
			area,
			content: instructorStr + prompt,
			context: TRANSLATE,
			newHistory: newAIContext,
			sessionId: AISession,
			errandId
		}
		onSend(TRANSLATE, payload);
	}

	const onChangeChatText = (e) => {
		setLiveChatTxt(e.target.value);
	}

	const onPressEnter = (e) => {
		if (e.keyCode == 13 && !e.shiftKey) {
			e.preventDefault();
			sendText(CHAT);
		}
	}

	const sendText = (context) => {
		let allowSend = true;
		let content = liveChatTxt;
		let newHistory = false;
		if(context == REGENERATE){
			//regenerate the last answer received
			const prevAnswer = dataSrc.answers.sort((a, b) => b.received - a.received)[0];
			content = prevAnswer.content;
			if(typeof generateAnswer === "function") {
				generateAnswer();
			}
		} else if (context == CHAT) {
			if(liveChatTxt == ""){
				allowSend = false;
			}
		}
		const errandId = eid ? eid.toString() : selectedLibraryQuestion ? "qLibrary-"+selectedLibraryQuestion : "";
		if(allowSend){
			const payload = {
				area,
				content,
				context,
				newHistory,
				errandId: errandId,
				sessionId: AISession
		};
			onSend(context, payload);
			setLiveChatTxt("");
		}
	}

	const scrollToBottom = () => {
		if (messageComponentRef.current) {
			messageComponentRef.current.scrollTop = messageComponentRef.current.scrollHeight;
		}
	}

	useEffect(() => {
		if(dataSrc && dataSrc.answers && dataSrc.answers.length > 0){
			setHasAnswer(true);
			setHasPrevAnswer(true);
		} else {
			setHasAnswer(false);
		}
		scrollToBottom();
	}, [dataSrc]);

	useEffect(() => {
		const isEmpty = typeof currentAnswer === "string" && currentAnswer.trim() === "";
		if(!isEmpty){
			setDisableRephraseInit(false);
		} else {
			setDisableRephraseInit(true);
		}
	}, [currentAnswer]);

	//if it's chat, allow rephrase always
	useEffect(() => {
		if(isChat) {
			setDisableRephraseInit(false);
		}
	}, [isChat]);

	const onInsertAnswer = (text, isChat) => {
		onInsertAIAnswer(text, isChat, src, popup);
	}
	let panelHeight = '100%';
	if (features["openai-show-chat-panel"]) {
		panelHeight = popup ? '75%' : verticalView ? '85%' : '90%';
	} else {
		panelHeight = popup ? '85%' : verticalView ? '95%' : '100%';
	}

	const customInstructionHelper = <CustomizeInstructionInfo customAIInst={customAIInst} customAIResp={customAIResp} />;
	const infoElement = <i className="icon-alert" style={{transform: "rotate(180deg)", marginLeft: "1rem", fontSize: "14px"}}></i>;

	return (
		<div className="rewrite-answer-box-content panel" style={{height: panelHeight}} ref={messageComponentRef}>
			{
				customAIInst || customAIInst ?
				<div style={{padding: '5px 10px'}}>
					<Helper triggerElement={infoElement}>{customInstructionHelper}</Helper>
				</div>
				: null
			}
			{
				//Asking agent what to do
				currentAction !== START_OVER ?
				<Fragment>
					<div className="rewrite-answer-box-content-bot" style={{padding: '8px 25px ', display: 'flex', gap: '10px'}}>
						<div className="rewrite-answer-box-content-bot-icon-chatbot">
							<i className="icon-chatbot"></i>
						</div>
						<div className="rewrite-answer-box-content-bot-text c3-typing-container" style={GreetingPromptBoxStyle}>
							<div className={(!popup && !hasAnswer)? "c3-typing-animation" : ""}>
								{commonAIPrompt}
							</div>
						</div>
					</div>
					{
						(!hasAnswer && !generatingAns) ?
						<div hidden={true} className="rewrite-answer-box-content-bot enhance" style={{padding: '8px 50px', display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
							<div className="rewrite-answer-box-content-bot-icon-enhance">
								<AIEnhanceAnswerOptions
									title={I("Rephrase")}
									action={REPHRASE_INIT}
									disabled={disableRephraseInit}
									active={activeEnhance == REPHRASE_INIT ? true : false}
									onClick={enhanceAnswer} />
							</div>
						</div> : null
					}
				</Fragment> : null
			}
			{
				Object.keys(dataSrc).length > 0 &&
					<AIMessageComponent msgs={dataSrc} isChat={isChat} onInsert={onInsertAnswer} onCopy={onCopy}/>
			}
			{ !cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") || features["openai-agent-assist-uses-openai"] &&
				<div hidden={!generatingAns} style={{textAlign: 'center', color: '#6d6d6d'}}><Spinner /></div>
			}
			<div hidden={false} className="rewrite-answer-box-content-bot enhance" style={{padding: '8px 50px', display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
				{
					Object.keys(ListOfAIEnhanceAnswerOptions).map((key, index) => {
						const phrase = ListOfAIEnhanceAnswerOptions[key].title;
						const action = ListOfAIEnhanceAnswerOptions[key].action;
						const type = ListOfAIEnhanceAnswerOptions[key].type;
						const data = ListOfAIEnhanceAnswerOptions[key].data;
						if (!ListOfAIEnhanceAnswerOptions[key].hide) {
							return (
								<div key={index} className="rewrite-answer-box-content-bot-icon-enhance">
									<AIEnhanceAnswerOptions
										title={phrase}
										action={action}
										active={activeEnhance == action ? true : false}
										type={type}
										data={data}
										showOptions={showOptions}
										setShowOptions={setShowOptions}
										favLangs={agentFavouriteAITranslateLangs}
										onUpdateLangFavourites={onUpdateLangFavourites}
										onClick={enhanceAnswer}
										onSelectLanguage={translateAnswer}
										ref={messageComponentRef}
									/>
								</div>
							)
						}
					})
				}
			</div>
			{
				features["openai-show-chat-panel"] &&
				<div className="rewrite-answer-box-content-textarea">
					<textarea
						onChange={onChangeChatText}
						placeholder={I('Insert your prompt here')}
						value={liveChatTxt}
						onKeyDown={(e) => {onPressEnter(e)}}
					/>
					<div className="rewrite-answer-box-content-button" style={{display: 'flex', gap: '8px', justifyContent: 'flex-end'}}>
						<div className="rewrite-answer-box-content-button-regenerate">
							<button onClick={() => sendText(REGENERATE)} className="btn-ai-pink-border" disabled={!hasPrevAnswer} title={I("Regenerate previous answer")}>
								<i className="icon-refresh"></i>
								{I('Regenerate')}
							</button>
						</div>
						<div className="rewrite-answer-box-content-button-send" title={I("Chat with AI")}>
							<button onClick={() => sendText(CHAT)} className="btn-ai-pink-fill" disabled={liveChatTxt == "" ? true : false}>
								{I('Send')}
							</button>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

const AIMessageBubble = ({ content = "" }) => {
	if (content) {
		const isHTML = /<[a-z][\s\S]*>/i.test(content);
		let formattedContent = content;
		if (isHTML) {
			formattedContent = (
				<div dangerouslySetInnerHTML={{ __html: content }} />
			);
		} else {
			formattedContent = content.trim();
		}
		return (
			<div>
				{formattedContent}
			</div>
		);
	} else {
		return null;
	}
}

const AIMessageComponent = ({ msgs, isChat, onCopy, onInsert }) => {
	const sortedMsgs = [...msgs.questions, ...msgs.answers].sort((a, b) => a.received - b.received);
	const [copied, setCopied] = useState("");

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied("");
			}, 1000);
		}
	}, [copied]);

	const handleCopy = (text, index) => {
		setCopied(index);
		onCopy(text);
	};

	const renderMessage = (message, index) => {
		const isQuestion = message.isQuestion || false;
		const useStyle = !isQuestion ? AIPromptBoxStyle : agentPromptBoxStyle;
		const reformat = true;
		const innerMsgStyle = !isQuestion ? innerTxtStyle(reformat) : innertxtPromptStyle;
		const chatBubbleClass = !isQuestion ? "rewrite-answer-box-content-bot" : "rewrite-answer-box-content-user";

		if (message && message.content) {
			const msgKey = !isQuestion ? "ai-msg-" + index : "user-msg-" + index;
			return (
				<div key={msgKey} className={chatBubbleClass}>
					{
						!isQuestion &&
						<div className={!isQuestion ? "rewrite-answer-box-content-bot-icon-chatbot" : ""}>
							<i className="icon-chatbot" />
						</div>
					}
					<div className={!isQuestion ? "rewrite-answer-box-content-bot-text" : "rewrite-answer-box-content-user-text"} style={useStyle}>
						<div style={innerMsgStyle}>
							<AIMessageBubble content={message.content} />
						</div>
					</div>
					{
						!isQuestion &&
							<div className={"rewrite-answer-box-content-bot-icon"}>
							<div className="rewrite-answer-box-content-bot-icon-copy action" title={I("Copy")}>
								<i className={copied === index ? 'icon-new-tick-single' : 'icon-copy'} onClick={() => handleCopy(message.content, index)} />
							</div>
							<div className="rewrite-answer-box-content-bot-icon-insert action" title={I("Use answer")}>
								<i className="icon-request" onClick={() => onInsert(message.content, isChat)} style={{ display: 'inline-block', transform: 'rotateX(180deg)' }} />
							</div>
						</div>
					}
				</div>
			);
		}
	};

	return (
		<>
			{sortedMsgs.map((message, index) => {
				return renderMessage(message, index);
			})}
		</>
	);
};

